import React, { Component } from "react";
import { Modal, Button, Form, Toast } from "react-bootstrap";
import Enums from "../modules/Enums";
import { Auth } from "aws-amplify";
import Agents from "../modules/Agents.js";

class CreateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agentsFromApi: [],
      saveInProgress: false,
      saveFailed: false,
      agent: null,
      isLoading: true,
      firstName: "",
      lastName: "",
      userName: "",
      routing: "",
      routingList: [],
      routingFromApi: [],
      routingNameForConfirm: "",
      security: [],
      securityList: [],
      securityFromApi: [],
      securityNameForConfirm: [],
      hierarchy: "",
      hierarchyListFromApi: [],
      hierarchyFiltered: [],
      deskPhone: "",
      newDeskPhone: "",
      workTimeOut: 0,
      autoAccept: false,
      errorMessage: [],
      softOrDesk: "SOFT_PHONE",
      confirm: false,
      isUserCreated: false,
    };
  }

  componentDidMount() {
    this.executeOnMount();
  }

  executeOnMount = async () => {
    const data = this.getData();
    const finalResult = await data;
    try {
      this.filterSecurityList();
      this.filterRoutingList();
    } catch (err) {
      console.error(`An error has occurred in the filtering ${err}`);
    }
  };

  getData = async () => {
    this.setState({
      isLoading: true,
    });
    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();
    try {
      if (idJwtToken) {
        const securityFromApi = await Agents.getSecurityList();
        this.setState({
          securityFromApi,
        });
        const routingFromApi = await Agents.getRoutingList();
        this.setState({
          routingFromApi,
        });
        const agentsFromApi = await Agents.getAgents(
          Enums.LinesOfBusinessForAPI[this.props.currentLineOfBusiness],
          idJwtToken
        );
        this.setState({
          agentsFromApi,
        });
      }
    } catch (error) {
      console.error("there was an error getting data", error);
    }
    this.setState({
      isLoading: false,
    });
  };

  filterSecurityList = () => {
    const listFromApi = this.state.securityFromApi.SecurityProfileSummaryList;
    const securityListFiltered = listFromApi.filter((list) =>
      list.Name.includes(this.props.currentLineOfBusiness)
    );
    this.setState({
      securityList: securityListFiltered,
    });
  };

  filterRoutingList = () => {
    const listFromApi = this.state.routingFromApi.RoutingProfileSummaryList;
    const routingListFiltered = listFromApi.filter((list) => {
      if (list.Name.includes(this.props.currentLineOfBusiness)) {
        return true;
      }
      return false;
    });
    this.setState({
      routingList: routingListFiltered,
    });
  };

  isUserAlreadyCreated = async () => {
    let result = this.state.agentsFromApi.find(
      (user) =>
        user.Username === this.state.userName ||
        user.FirstName === this.state.firstName ||
        user.LastName === this.state.lastName
    );
    result = result ? true : false;
    this.setState({
      isUserCreated: result,
    });
    return result;
  };

  handleUserModalClose = () => {
    this.setState({
      isUserCreated: false,
    });
    this.handleClose();
  };

  createUser = async (
    firstName,
    lastName,
    userName,
    softOrDesk,
    workTimeOut,
    autoAccept,
    deskPhone,
    routing,
    security
  ) => {
    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();
    if (idJwtToken) {
      const isCreateSuccess = await Agents.createNewUser(
        firstName,
        lastName,
        userName,
        softOrDesk,
        workTimeOut,
        autoAccept,
        deskPhone,
        routing,
        security,
        idJwtToken
      );
      if (isCreateSuccess.UserId) {
        return isCreateSuccess;
      } else if (Object.keys(isCreateSuccess).includes("ClientError")) {
        this.setState({ errorMessage: Object.values(isCreateSuccess) });
        return false;
      }
    } else {
      return true;
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "workTimeOut") {
      if (value >= 0) {
        this.setState({ [name]: parseInt(value, 10) });
      } else {
        this.setState({ [name]: 0 });
      }
    } else if (name === "softOrDesk") {
      if (value === "SOFT_PHONE") {
        this.setState({
          deskPhone: "",
          [name]: value,
        });
      } else {
        this.setState({
          [name]: value,
        });
      }
    } else if (name === "autoAccept") {
      let bool = false;
      value === "true" ? (bool = true) : (bool = false);
      this.setState({ [name]: bool });
    } else {
      this.setState({ [name]: value });
    }
  };

  handleRouteChange = (event) => {
    const { name, value } = event.target;
    let routeName = "";
    this.state.routingList.forEach(
      (route) => route.Id === value && (routeName = route.Name)
    );
    this.setState({
      [name]: value,
      routingNameForConfirm: routeName,
    });
  };

  handleConfirm = (e) => {
    e.preventDefault();
    this.setState({
      confirm: !this.state.confirm,
      errorMessage: [],
    });
  };

  handleSubmit = async () => {
    if (this.state.saveInProgress) {
      return;
    }
    this.setState({
      saveInProgress: true,
    });
    const result = await this.isUserAlreadyCreated();
    if (!result) {
      const isCreateUserSuccess = await this.createUser(
        this.state.firstName,
        this.state.lastName,
        this.state.userName,
        this.state.softOrDesk,
        this.state.workTimeOut,
        this.state.autoAccept,
        this.state.deskPhone,
        this.state.routing,
        this.state.security
      );
      if (isCreateUserSuccess) {
        this.setState({
          firstName: "",
          lastName: "",
          userName: "",
          routing: "",
          security: [],
          hierarchy: "",
          hierarchyListFromApi: [],
          hierarchyFiltered: [],
          deskPhone: "",
          newDeskPhone: "",
          workTimeOut: 0,
          autoAccept: false,
          errorMessage: [],
          saveFailed: false,
          saveInProgress: false,
          updateSucessful: true,
          softOrDesk: "SOFT_PHONE",
          confirm: false,
        });
        this.handleCloseWithConfirmation();
      } else {
        this.getData();
        this.setState({
          updateSucessful: false,
          saveInProgress: false,
          confirm: false,
        });
      }
    }
  };

  renderPhoneConfig = () => {
    return (
      <div>
        <div className={this.state.softOrDesk === "SOFT_PHONE" ? "" : "row"}>
          <Form.Group
            className={
              this.state.softOrDesk === "SOFT_PHONE"
                ? "formGroup"
                : "col formGroup mb-0 mr-4"
            }
          >
            <Form.Label className="formLabel">Desk or Soft Phone</Form.Label>
            <div className="selectFormSizeControl">
              <select
                required
                className="form-control"
                as="select"
                onChange={this.handleChange}
                name="softOrDesk"
              >
                <option value="SOFT_PHONE">Soft</option>
                <option value="DESK_PHONE">Desk</option>
              </select>
            </div>
          </Form.Group>
          {this.state.softOrDesk === "DESK_PHONE" && (
            <Form.Group className="col mb-0 formGroup">
              <Form.Label className="formLabel">Phone Number</Form.Label>
              <Form.Control
                required
                type="text"
                onChange={this.handleChange}
                name="deskPhone"
                value={this.state.deskPhone}
              />
            </Form.Group>
          )}
        </div>
        <div className={this.state.softOrDesk === "DESK_PHONE" ? "" : "row"}>
          <Form.Group
            controlId="acwTimeout"
            className={
              this.state.softOrDesk === "DESK_PHONE"
                ? "formGroup"
                : "col formGroup mb-0 mr-4"
            }
          >
            <Form.Label className="formLabel">ACW Time Limit</Form.Label>
            <Form.Control
              required
              type="number"
              min="0"
              value={this.state.workTimeOut}
              onChange={this.handleChange}
              name="workTimeOut"
            />
          </Form.Group>
          {this.state.softOrDesk === "SOFT_PHONE" && (
            <Form.Group controlId="autoAccept" className="col mb-0 formGroup">
              <Form.Label className="formLabel">Auto Accept Calls</Form.Label>
              <div className="selectFormSizeControl">
                <select
                  className="form-control"
                  as="select"
                  value={this.state.autoAccept}
                  onChange={this.handleChange}
                  name="autoAccept"
                >
                  <option value="false">Off</option>
                  <option value="true">On</option>
                </select>
              </div>
            </Form.Group>
          )}
        </div>
      </div>
    );
  };

  getTitle = () => {
    return this.state.confirm ? (
      <Modal.Title>Confirm User Details</Modal.Title>
    ) : (
      <Modal.Title>Create User</Modal.Title>
    );
  };

  getSaveButton = () => {
    if (
      !this.state.firstName.length ||
      !this.state.lastName.length ||
      !this.state.userName.length ||
      !this.state.security.length ||
      !this.state.routing.length ||
      (this.state.softOrDesk === "DESK_PHONE" && !this.state.deskPhone.length)
    ) {
      return (
        <Button disabled type="submit" className="mb-3 submitCloseButtons">
          Save
        </Button>
      );
    } else if (this.state.saveInProgress) {
      return (
        <Button disabled type="submit" className="mb-3 submitCloseButtons">
          Saving . . .
        </Button>
      );
    } else if (this.state.confirm) {
      return (
        <Button
          type="submit"
          className="mb-3 submitCloseButtons"
          onClick={this.handleSubmit}
          name="Save Button"
        >
          Create User
        </Button>
      );
    } else {
      return (
        <Button
          type="button"
          className="mb-3 submitCloseButtons"
          onClick={this.handleConfirm}
          name="Save Button"
        >
          Save
        </Button>
      );
    }
  };

  getCloseButton = () => {
    return this.state.confirm ? (
      <Button
        type="button"
        variant="outline-secondary"
        onClick={this.handleConfirm}
        className="mb-3 submitCloseButtons"
      >
        Go back
      </Button>
    ) : this.state.saveInProgress ? (
      <Button
        disabled
        variant="outline-secondary"
        className="mb-3 submitCloseButtons"
      >
        Cancel
      </Button>
    ) : (
      <Button
        variant="outline-secondary"
        onClick={this.handleClose}
        className="mb-3 submitCloseButtons"
        name="Close Button"
      >
        Cancel
      </Button>
    );
  };

  handleClose = () => {
    this.setState({
      firstName: "",
      lastName: "",
      userName: "",
      routing: "",
      security: [],
      hierarchy: "",
      hierarchyListFromApi: [],
      hierarchyFiltered: [],
      deskPhone: "",
      newDeskPhone: "",
      workTimeOut: 0,
      autoAccept: false,
      errorMessage: [],
      saveFailed: false,
      saveInProgress: false,
      softOrDesk: "SOFT_PHONE",
      confirm: false,
    });
    !this.state.isLoading && this.props.closeModal();
  };

  handleCloseWithConfirmation = () => {
    this.setState({
      firstName: "",
      lastName: "",
      userName: "",
      routing: "",
      security: [],
      hierarchy: "",
      hierarchyListFromApi: [],
      hierarchyFiltered: [],
      deskPhone: "",
      newDeskPhone: "",
      workTimeOut: 0,
      autoAccept: false,
      errorMessage: [],
      saveFailed: false,
      saveInProgress: false,
      softOrDesk: "SOFT_PHONE",
      confirm: false,
    });
    !this.state.isLoading && this.props.closeModalWithConfirmation();
  };

  renderRoutingSelect = () => {
    return (
      <Form.Group controlId="routingProfile" className="col formGroup mr-2">
        <Form.Label className="formLabel">Routing Profile</Form.Label>
        <div className="selectFormSizeControl">
          <select
            required
            className="form-control"
            as="select"
            onChange={this.handleRouteChange}
            name="routing"
            value={this.state.routing.length ? this.state.routing : ""}
          >
            <option value=""></option>
            {this.state.routingList.map((value, index) => {
              return (
                <option key={index} value={value.Id}>
                  {value.Name}
                </option>
              );
            })}
          </select>
        </div>
        {!this.state.routing.length && (
          <p className="ml-3 currentEmergencyMessage">
            At least one routing profile must be selected.
          </p>
        )}
      </Form.Group>
    );
  };

  handleSecurityCheckbox = (index) => {
    const newList = this.state.security;
    const newListNames = this.state.securityNameForConfirm;
    const { securityList } = this.state;
    if (this.state.security.includes(securityList[index].Id)) {
      newList.splice(newList.indexOf(securityList[index].Id), 1);
      newListNames.splice(newListNames.indexOf(securityList[index].Name), 1);
    } else {
      newList.splice(0, 0, securityList[index].Id);
      newListNames.splice(0, 0, securityList[index].Name);
    }
    this.setState({
      security: newList,
      securityNameForConfirm: newListNames,
    });
  };

  renderSecuritySelect = () => {
    return (
      <div>
        <Form.Group className="formGroup">
          <Form.Label className="formLabel">Security Profile</Form.Label>
        </Form.Group>
        <div className="row mt-3 mb-2">
          {this.state.securityList.map((value, index) => {
            return this.state.saveInProgress ? (
              <div className="col-4 mb-3" key={index}>
                <Form.Check
                  disabled
                  checked={this.state.security.includes(value.Id)}
                  label={this.state.securityList[index].Name}
                />
              </div>
            ) : (
              <div className="col-4 mb-3" key={index}>
                <Form.Check
                  name="security"
                  onChange={this.handleSecurityCheckbox.bind(value.Id, index)}
                  checked={this.state.security.includes(value.Id)}
                  label={this.state.securityList[index].Name}
                />
              </div>
            );
          })}
          {!this.state.security.length && (
            <span className="ml-3 currentEmergencyMessage">
              At least one security profile must be selected.
            </span>
          )}
        </div>
      </div>
    );
  };

  renderUser = () => {
    return (
      <div>
        <Form.Group className="formGroup">
          <Form.Label className="formLabel">First Name</Form.Label>
          <Form.Control
            required
            type="text"
            onChange={this.handleChange}
            name="firstName"
            value={this.state.firstName}
          />
          {!this.state.firstName.length && (
            <span className="currentEmergencyMessage">
              Required field. Cannot be left blank.
            </span>
          )}
          {/^[0-9]+$/.test(this.state.firstName) && (
            <span className="currentEmergencyMessage">
              First Name cannot contain numbers
            </span>
          )}
        </Form.Group>
        <Form.Group className="formGroup">
          <Form.Label className="formLabel">Last Name</Form.Label>
          <Form.Control
            required
            type="text"
            onChange={this.handleChange}
            name="lastName"
            value={this.state.lastName}
          />
          {!this.state.lastName.length && (
            <span className="currentEmergencyMessage">
              Required field. Cannot be left blank.
            </span>
          )}
          {/^[0-9]+$/.test(this.state.lastName) && (
            <span className="currentEmergencyMessage">
              Last Name cannot contain numbers
            </span>
          )}
        </Form.Group>
        <Form.Group className="formGroup">
          <Form.Label className="formLabel">Username</Form.Label>
          <Form.Control
            required
            type="text"
            onChange={this.handleChange}
            name="userName"
            value={this.state.userName}
          />
          {!this.state.userName.length && (
            <span className="currentEmergencyMessage">
              Required field. Cannot be left blank.
            </span>
          )}
        </Form.Group>
        {this.renderPhoneConfig()}
        <div className="row mt-2">{this.renderRoutingSelect()}</div>
        {this.renderSecuritySelect()}
      </div>
    );
  };

  confirmationDataList = () => {
    return (
      <ul>
        <li>
          <b>First Name: </b>
          {this.state.firstName}
        </li>
        <li>
          <b>Last Name: </b>
          {this.state.lastName}
        </li>
        <li>
          <b>Username: </b>
          {this.state.userName}
        </li>
        <li>
          <b>Phone Type: </b>
          {this.state.softOrDesk === "SOFT_PHONE" ? "Soft Phone" : "Desk Phone"}
        </li>
        {this.state.deskPhone.length ? (
          <li>
            <b>Desk Phone #: </b>
            {this.state.deskPhone}
          </li>
        ) : null}
        <li>
          <b>ACW Time Limit: </b>
          {this.state.workTimeOut}
        </li>
        <li>
          <b>Auto Accept: </b>
          {this.state.autoAccept === true ? "Yes" : "No"}
        </li>
        <li>
          <b>Routing Profile: </b>
          {this.state.routingNameForConfirm}
        </li>
        <li>
          <b>Security Profile: </b>
          {this.state.securityNameForConfirm.map(
            (listItem, index) => `${index > 0 ? ",  " : ""}${listItem}`
          )}
        </li>
      </ul>
    );
  };

  render() {
    let errorMessages = [];
    if (this.state.errorMessage.length) {
      errorMessages = this.state.errorMessage.map((message, index) => (
        <p className="currentEmergencyMessage" key={index}>
          {message}
        </p>
      ));
    }

    return (
      <>
        <>
          {this.state.isUserCreated && (
            <Modal
              show={this.state.isUserCreated}
              onHide={this.handleUserModalClose}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Warning</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form className="container">
                  User Already Created. Please try again.
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="agentTableButton"
                  onClick={this.handleUserModalClose}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </>
        <Modal show={this.props.modalStatus} onHide={this.handleClose}>
          <Modal.Header closeButton>{this.getTitle()}</Modal.Header>
          {this.state.securityList && this.state.routingList && (
            <Modal.Body>
              {errorMessages}
              {this.state.confirm
                ? this.confirmationDataList()
                : this.renderUser()}
              <Form className="container">
                {this.getSaveButton()}
                {this.getCloseButton()}
              </Form>
            </Modal.Body>
          )}
        </Modal>
      </>
    );
  }
}

export default CreateUser;
