import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import {
  Container,
  Button,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EmergencyMessage from '../modules/EmergencyMessage';
import Description from '../modules/Description';
import Logger from '../modules/Logger.js';
import Enums from '../modules/Enums';
import EmergencyMessageModal from './EmergencyMessageModal';
import DescriptionEditModal from './DescriptionEditModal.js';
import EndMessage from './EndMessage';
import TimeZone from 'moment-timezone';
import Utils from '../modules/Utils';
import Constants from '../modules/Constants';

TimeZone.tz.setDefault(Constants.TimeZone.PACIFIC_TIME);

export default class EmergencyMesssage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      showEndEmergencyMessage: false,
      emergencyMessage: null,
      descriptionModal: false,
      description: null,
      idJwtToken: null,
    };
  }

  componentDidMount() {
    this.loadEmergencyMessage();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== undefined
      && prevProps.currentLineOfBusiness
      !== this.props.currentLineOfBusiness) {
      this.loadEmergencyMessage();
    }
  }

  async loadEmergencyMessage() {

    if (this.props.currentLineOfBusiness === undefined) {
      return;
    }
    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();

    if (idJwtToken) {
      const emergencyMessage =
        await EmergencyMessage.getEmergencyMessage(
          Enums.LinesOfBusinessForAPI[this.props.currentLineOfBusiness],
          idJwtToken,
        );

      const description =
        await Description.getDescriptionMessage(
          'emergencyMessage',
          idJwtToken
        );

      this.setState({
        emergencyMessage: emergencyMessage,
        description,
        idJwtToken,
      });
    } else {
      Logger.verbose(JSON.stringify(`JWT TOKEN is null: ${idJwtToken}`));
    }
  }

  openModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  }

  openEndEmergencyModal = () => {
    this.setState({
      showEndEmergencyMessage: true,
    });
  }

  updateLocalEmergecnyMessage = (newEmergencyMessage) => {
    this.setState({ emergencyMessage: newEmergencyMessage });
  }

  closeEndEmergencyModal = () => {
    this.setState({
      showEndEmergencyMessage: false,
    });
  }

  openDescriptionEditModal = () => {
    this.setState({
      descriptionModal: true,
    });
  }

  closeDescriptionModal = () => {
    this.setState({
      descriptionModal: false,
    });
  }

  updateLocalDescription = (newDescription) => {
    this.setState({
      description: newDescription,
    });

    return true;
  }

  buildEmergencyMessageElement = (
    className,
    description,
    startTime,
    endTime,
    upcomingOrCurrent,
  ) => {
    return <div className="row">
      <div className="textGroup col-lg-3 col-12">
        <div className="textLabel">Status</div>
        <div className="{className}">{upcomingOrCurrent}</div>
      </div>
      <div className="textGroup col-lg-3 col-12">
        <div className="textLabel">Description</div>
        <div >{description}</div>
      </div>
      <div className="textGroup col-lg-3 col-12">
        <div className="textLabel">Start Time</div>
        <div >{startTime}</div>
      </div>
      <div className="textGroup col-lg-3 col-12">
        <div className="textLabel">End Time</div>
        <div >{endTime}</div>
      </div>
    </div>;
  }

  buildEndEmergencyMessageButton = (buttonTitle) => {
    return <Button
      variant="outline-danger"
      onClick={this.openEndEmergencyModal}>
      {buttonTitle}
    </Button>;
  }

  buildEmergencyMessageContainer = (
    title,
    message,
    buttonTitle,
    endButton,
  ) => {
    return <Container className="containerBody mb-2">
      <div className="businessHoursTitle">
        {title}
        <OverlayTrigger trigger="click" placement="right" overlay={
          <Popover
            id="popover-basic" title="Emergency Message Description">
            {this.state.description}
          </Popover>
        }>
          <span>
            <FontAwesomeIcon
              className="editIcon action-icon mr-2"
              icon="comment"
              variant="success"
            />
          </span>
        </OverlayTrigger>
        {this.props.permissions.isGlobalAdmin ?
          <FontAwesomeIcon
            className="editIcon action-icon mr-2"
            icon="pen"
            onClick={() => this.openDescriptionEditModal()}
          /> : null}
      </div>
      <div>
        {message}
      </div>
      <div className="emergencyButtonGroup">
        <Button
          onClick={this.openModal}>
          {buttonTitle}
        </Button>
        {endButton}
      </div>

    </Container>;
  }

  endEmergencyMessage = () => {
    const idJwtToken = this.state.idJwtToken;

    if (idJwtToken) {
      const textValues = {
        title: 'End Emergency Message',
        message: 'Are you sure you want to end the emergency message?',
        buttonText: 'End Emergency Message',
      };

      const message = { ...this.state.emergencyMessage };
      message.endDateTime = String(Date.now());
      message.lastUpdatedBy = this.props.userName;

      const updateFunction = () => EmergencyMessage.updateEmergencyMessage(
        {
          LOB: Enums.LinesOfBusinessForAPI[this.props.currentLineOfBusiness],
          message: message,
          idJwtToken,
        }
      );

      return <EndMessage
        endModalStatus={this.state.showEndEmergencyMessage}
        closeModal={this.closeEndEmergencyModal}
        message={message}
        updateFunction={updateFunction}
        textValues={textValues}
        updateLocalMessage={this.updateLocalEmergecnyMessage}
      />;
    } else {
      Logger.verbose(JSON.stringify(`JWT TOKEN is null: ${idJwtToken}`));
    }
  };

  render() {
    let message;
    let emergencyMessage;

    const messageStatus = Utils.messageStatus(
      this.state.emergencyMessage
    );

    if (this.state.emergencyMessage === null) {
      return <div />;
    }

    const startTime = TimeZone(
      Number(this.state.emergencyMessage.startDateTime))
      .format(Constants.DateFormat.FULL_DATE);

    const endTime = this.state.emergencyMessage.endDateTime === null
      ? 'Undetermined'
      : TimeZone(
        Number(this.state.emergencyMessage.endDateTime))
        .format(Constants.DateFormat.FULL_DATE);

    switch (messageStatus) {
      case 'UPCOMING':
        message = this.buildEmergencyMessageElement(
          'upcomingEmergencyMessage',
          this.state.emergencyMessage.description,
          startTime,
          endTime,
          'UPCOMING',
        );

        emergencyMessage = this.buildEmergencyMessageContainer(
          'Emergency Messsage (PST)',
          message,
          'Edit Emergency Message',
          this.buildEndEmergencyMessageButton('End Emergency Message')
        );
        break;
      case 'CURRENT':
        message = this.buildEmergencyMessageElement(
          'currentEmergencyMessage',
          this.state.emergencyMessage.description,
          startTime,
          endTime,
          'CURRENT',
        );

        emergencyMessage = this.buildEmergencyMessageContainer(
          'Emergency Messsage (PST)',
          message,
          'Edit Emergency Message',
          this.buildEndEmergencyMessageButton('End Emergency Message'),
        );
        break;
      case 'EXPIRED':
      case null:
        emergencyMessage = this.buildEmergencyMessageContainer(
          'Emergency Messsage (PST)',
          'No current emergency message',
          'Create Emergency Message',
        );
    }

    const endModal = this.state.showEndEmergencyMessage
      ? this.endEmergencyMessage()
      : null;

    const modal = this.state.showModal
      ? <EmergencyMessageModal
        modalStatus={this.state.showModal}
        closeModal={this.closeModal}
        emergencyMessage={this.state.emergencyMessage}
        currentLineOfBusiness={this.props.currentLineOfBusiness}
        updateLocalEmergecnyMessage={this.updateLocalEmergecnyMessage}
        userName={this.props.userName}
      />
      : null;

    const descriptionModal = this.state.descriptionModal
      ? <DescriptionEditModal
        modalStatus={this.state.descriptionModal}
        closeModal={this.closeDescriptionModal}
        currentLineOfBusiness={this.props.currentLineOfBusiness}
        updateLocalMessage={this.updateLocalDescription}
        description={this.state.description}
        title={'Emergency Message Description'}
        messageType={'emergencyMessage'}
      />
      : null;
    return (
      <div>
        {emergencyMessage}
        {modal}
        {endModal}
        {descriptionModal}
      </div>
    );
  }
}
