import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';

import Logger from '../modules/Logger.js';
import AmplifySettings from '../modules/AmplifyConfigure.js';
import { Auth } from 'aws-amplify';

import Settings from '../modules/Settings.js';
import IdleTimer from 'react-idle-timer';
const events = ['mousedown', 'keypress'];

export default class Signin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idleTimer: null,
      warning: false,
      timeOut: Settings.timeOut,
    };
  }

  componentDidMount() {
    setInterval(this.warningMessage, 100);
  }

  warningMessage = () => {
    if (this.state.idleTimer) {
      const remainingTime = Math.floor(
        this.state.idleTimer.getRemainingTime() / 1000
      );
      const warningTime = 120;
      if (remainingTime === warningTime) {
        this.setState({
          warning: true,
        });
      }
    }
  };

  openCognitoSignInUI = async () => {
    if (AmplifySettings.oauth.federatedProvider) {
      const signinParams = {
        provider: AmplifySettings.oauth.federatedProvider,
      };
      Auth.federatedSignIn(signinParams);
    } else {
      const domain = AmplifySettings.oauth.domain;
      const redirectSignIn = AmplifySettings.oauth.redirectSignIn;
      const responseType = AmplifySettings.oauth.responseType;
      const clientId = this.props.auth.userPool.clientId;
      const url = 'https://' + domain + '/login?redirect_uri=' + redirectSignIn
        + '&response_type=' + responseType + '&client_id=' + clientId;

      window.location.assign(url);
    }
  };

  signOut = async () => {
    try {
      await Auth.signOut({ global: true });
    } catch (err) {
      Logger.error(err);
    }
  };

  action = (e) => {
    this.setState({
      warning: false,
    });
  };

  idle = (e) => {
    this.signOut();
  };

  handleClose = () => {
    this.setState({
      warning: false,
    });
  };

  render() {
    let content;
    if (this.props.isloggedIn) {
      content = <div>
        <div className="userName">
          {this.props.userName}
        </div>
        <Button
          variant="secondary"
          onClick={this.signOut}>
          Sign Out
        </Button>
        <IdleTimer
          ref={ref => { this.state.idleTimer = ref; }}
          element={document}
          onActive={this.active}
          onIdle={this.idle}
          onAction={this.action}
          timeout={this.state.timeOut}
          events={events}
        />

        <Modal show={this.state.warning} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Log Out Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            You will be logged out within 2 minutes due to your inactivity.
          </Modal.Body>
        </Modal>
      </div>;
    } else if (this.props.signInloading) {
      content = <Button
        variant="secondary"
        disabled>
        Signing In...
      </Button>;
    } else {
      if (this.props.auth.userPool && this.props.auth.userPool.clientId) {
        this.openCognitoSignInUI();
       }
    }
    return (
      <div>
        {content}
      </div>
    );
  }
}
