import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { Container, Table, Button } from 'react-bootstrap';
import Logger from '../modules/Logger.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons'
import { pagination, sortByColumn, getDataForCurrentPage, resetPaginationOptions } from '../modules/TableTools';

import Agents from '../modules/Agents';
import Permissions from '../modules/Permissions';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

const moment = require('moment');

export default class RealTimeTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      agents: [],
      agentsForCurrentPage: [],
      permissions: {
        isGLobalAdmin: false,
      },
      columns: [
        {
          dataField: 'userid', text: 'Agent Login', sort: 'true', editable: false,
          headerStyle: () => {
            return { width: '280px', textAlign: 'center' };
          },
        },
        {
          dataField: 'Activity', text: 'Activity', sort: 'true', editable: false,
          headerStyle: () => {
            return { width: '200px', textAlign: 'center' };
          },
        },
        {
          dataField: 'Duration', text: 'Duration', editable: false,
          headerStyle: () => {
            return { width: '100px', textAlign: 'center' };
          },
        },
        {
          dataField: 'Queue', text: 'Queue', sort: 'true', editable: false,
          headerStyle: () => {
            return { width: '200px', textAlign: 'center' };
          },
        },
        {
          dataField: 'RoutingProfile', text: 'Routing Profile', sort: 'true', editable: false,
          headerStyle: () => {
            return { width: '200px', textAlign: 'center' };
          },
        },
      ],
      isLoading: false,
      pagination: pagination,
      sortOrder: 'asc',
      sortField: 'userid'
    };
  
    this.getPermissions = this.getPermissions.bind(this);
    this.loadAgents = this.loadAgents.bind(this);
  }

    componentDidMount() {
    this.getPermissions();
    this.loadAgents();
    this.timer = setInterval(this.loadAgents, 15000);
  }

  componentDidUpdate(prevProps, nextProps) {
    if (prevProps !== undefined
      && prevProps.currentLineOfBusiness !== this.props.currentLineOfBusiness
    ) {
      this.loadAgents();
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    let pagination = resetPaginationOptions();;
    this.setState({
      pagination: pagination,
      sortOrder: 'asc',
      sortField: 'userid'
    });
  }

  getPermissions = async () => {
    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();
    if (idJwtToken) {
      const permissions = await Permissions.getPermissions(idJwtToken);

      this.setState({
        permissions,
      });
    }
  }

  loadAgents = async () => {
    if (this.props.currentLineOfBusiness === undefined) {
      return;
    }

    this.setState({
      agents: [],
      isLoading: true,
    });

    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();
    let momentNow = moment();
    var currentEpochSecs = Math.floor(momentNow / 1000);

    if (idJwtToken) {
      const agents =
        await Agents.agentStatus(
          this.props.currentLineOfBusiness,
          idJwtToken,
          this.props.permissions
        );
      if (agents && agents.length) {
        agents.forEach(agent => {
          const agentContactEpochSecs = Math.floor(moment(agent['timestamp']).unix());
          agent['Duration'] = moment.utc((currentEpochSecs - agentContactEpochSecs) * 1000).format('HH:mm:ss');

          {/*if (agent['Activity'].toUpperCase() === "after contact work".toUpperCase()) {
            agent['channel'] = <span>Voice</span>;
          }
          else if (agent['Activity'].toUpperCase() === "on contact".toUpperCase()) {
            agent['channel'] = <span>Voice <FontAwesomeIcon className="ml-3" icon={faVolumeUp} /></span>;
          }
          else
            agent['channel'] = <span></span>;*/}
        })

        pagination.totalSize = agents.length;

        this.setState({
          agents,
          isLoading: false,
          pagination: pagination
        });

        this.sortAgents(this.state.sortField, this.state.sortOrder);
        this.setAgentsForCurrentPage();

      } else {
        this.setState({
          isLoading: false,
        });
      }


    } else {
      Logger.verbose(JSON.stringify(`JWT TOKEN is null: ${idJwtToken}`));
    }

  }

  setAgentsForCurrentPage = () => {
    this.setState({
      agentsForCurrentPage: getDataForCurrentPage(this.state.agents, this.state.pagination)
    });
  }

  sortAgents = (sortField, sortOrder) => {
    this.state.agents = sortByColumn(this.state.agents, sortField, sortOrder);
    this.setState({
      sortOrder: sortOrder,
      sortField: sortField
    });
  }

  onTableChange = (type, newState) => {

    this.sortAgents(newState.sortField, newState.sortOrder);
    pagination.page = newState.page;
    pagination.sizePerPage = newState.sizePerPage;

    this.setState({
      pagination: pagination
    });

    this.setAgentsForCurrentPage();
  }

  render() {

    return (
      <div>
        <div className="planned-messages">
          <Container className="mb-3 livetable">
            <BootstrapTable
              remote
              onTableChange={this.onTableChange}
              bootstrap4
              keyField="userid"
              data={this.state.agentsForCurrentPage}
              columns={this.state.columns}
              pagination={paginationFactory(this.state.pagination)}
            />
          </Container>
        </div>
      </div>
    );
  }
}
