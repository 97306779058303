import moment from 'moment';
import Constants from './Constants';
import { UtteranceStatus } from '../modules/Enums';
const HOUR_FORMAT = 'H:mm';
const DAY = 'day';

export default class Utils {
  static convertToTwentyFourHour = time => {
    time = time ? time : '12:00 pm';

    const twentyFourHourFormat = moment(time, 'hh:mm a').format('HH:mm');
    return twentyFourHourFormat;
  };

  static validateTime = (currentDayHours, startOrEnd, time) => {
    const placeHolderDate = '01/01/2011';
    let startTime;
    let endTime;

    if (startOrEnd === 'startTime') {
      startTime = Date.parse(placeHolderDate + ' ' + time);
      endTime = Date.parse(placeHolderDate + ' ' + currentDayHours['endTime']);
    } else {
      startTime = Date.parse(
        placeHolderDate + ' ' + currentDayHours['startTime']
      );
      endTime = Date.parse(placeHolderDate + ' ' + time);
    }

    return endTime > startTime;
  };

  static compareStartAndEndTime = (startTime, endTime) => {
    const placeHolderDate = '01/01/2011';

    if (startTime === null && endTime === null) {
      return true;
    }

    startTime = Date.parse(placeHolderDate + ' ' + startTime);
    endTime = Date.parse(placeHolderDate + ' ' + endTime);

    return endTime > startTime;
  };

  static checkValidHours = listOfHours => {
    const hasInvalidHours = listOfHours.map(x => {
      if (!x.validTime) {
        return true;
      }
    });

    return hasInvalidHours.includes(true);
  };

  static getDateTimeAsIso = dateTime => {
    return new Date(dateTime).toISOString();
  };

  static transformToEditableHours = businessHours => {
    return {
      ...businessHours,
    };
  };

  static formatLOBForUI = (linesOfBusiness, LinesofBusinessForUI) => {
    const formattedLOB = linesOfBusiness.map(x => {
      return LinesofBusinessForUI[x];
    });

    return formattedLOB;
  };

  static formatEmergencyMessageToApi = (state, userName) => {
    const endTime = state.endUnknown ? null : String(state.endDateAndTime);
    const startTime = state.startImmediate
      ? String(Date.now())
      : String(state.startDateAndTime);

    return {
      description: state.description,
      type: 'EMERGENCY',
      category: state.category,
      startDateTime: startTime,
      endDateTime: endTime,
      lastUpdatedDateTime: String(Date.now()),
      lastUpdatedBy: userName,
      response_en: {
        type: state.responseType,
        value: state.englishResponse,
      },
      response_fr: {
        type: state.responseType,
        value: state.frenchResponse,
      },
    };
  };

  static formatSituationalMessageToAPI = (state, userName) => {
    return {
      description: state.description,
      type: state.type,
      category: state.category,
      startDateTime: String(state.startDateTime),
      endDateTime: String(state.endDateTime),
      lastUpdatedDateTime: String(Date.now()),
      lastUpdatedBy: userName,
      response_en: {
        type: state.responseType,
        value: state.englishResponse,
      },
      response_fr: {
        type: state.responseType,
        value: state.frenchResponse,
      },
    };
  };

  static formatTempMessageToAPI = (state, userName) => {
    const endTime = state.endUnknown ? null : String(state.endDateTime);
    const startTime = state.startImmediate
      ? String(Date.now())
      : String(state.startDateTime);

    return {
      description: state.description,
      type: state.type,
      category: state.category,
      startDateTime: startTime,
      endDateTime: endTime,
      lastUpdatedDateTime: String(Date.now()),
      lastUpdatedBy: userName,
      response_en: {
        type: state.englishResponseType,
        value: state.englishResponse,
      },
      response_fr: {
        type: state.frenchResponseType,
        value: state.frenchResponse,
      },
    };
  };

  static formatRecurringMessageToAPI = (state, cccode) => {

    return {
      cccode: Number(cccode),
      recurringMessageId: state.recurringMessageId,
      description: state.description,
      duration: state.duration,
      endDate: state.endDateTime,
      response_en: {
        type: state.responseType,
        value: state.englishResponse,
      },
      response_fr: {
        type: state.responseType,
        value: state.frenchResponse,
      },
      schedule: state.schedule,
      startDate: state.startDateTime,
      startTime: state.startTime
    };
  };

  static messageStatus = emergencyMessage => {
    if (emergencyMessage === null || emergencyMessage === undefined) {
      return null;
    } else if (
      emergencyMessage.endDateTime &&
      emergencyMessage.endDateTime < Date.now()
    ) {
      return 'EXPIRED';
    } else if (emergencyMessage.startDateTime > Date.now()) {
      return 'UPCOMING';
    } else {
      return 'CURRENT';
    }
  };

  static isDescriptionValid = description => {
    return description.length > 0 && description.length <= 50;
  };

  static isResponseValid = (currentLanguageResponse, otherLanguageResponse) => {
    return (
      currentLanguageResponse.length > 0 &&
      currentLanguageResponse.length <= Constants.TextLength.MAX &&
      otherLanguageResponse.length > 0 &&
      otherLanguageResponse.length <= Constants.TextLength.MAX
    );
  };

  static isDateAndTimeValid = ({
    endUnknownSelected,
    startImmediateSelected,
    selectedEndTime,
    selectedStartTime,
    currentTime,
  }) => {
    return (
      (startImmediateSelected && endUnknownSelected) ||
      (startImmediateSelected && selectedEndTime > currentTime) ||
      endUnknownSelected ||
      selectedEndTime > selectedStartTime
    );
  };

  static isEndTimeValid = ({
    selectedEndTime,
    currentTime,
    endUnknownSelected,
  }) => {
    return endUnknownSelected || selectedEndTime > currentTime;
  };

  static isPlannedDateAndTimeValid = ({
    selectedStartTime,
    selectedEndTime,
    currentTime,
  }) => {
    return selectedEndTime > selectedStartTime;
  };

  static isPlannedEndTimeValid = ({ selectedEndTime, currentTime }) => {
    return selectedEndTime > currentTime;
  };

  static isPlannedStartTimeValid = (
    currentTimeSelection,
    allPlannedMessages,
    previousPlannedMessage,
    index
  ) => {
    const checkIfOverlap = allPlannedMessages.map((message, messageIndex) => {
      if (
        (message.category !== 'RECURRING_MEETING' && index !== messageIndex) ||
        index === null
      ) {
        const existingStartDate = new Date(Number(message.startDateTime));
        const selectedStartDate = new Date(
          currentTimeSelection.selectedStartTime
        );
        return (
          existingStartDate.getFullYear() === selectedStartDate.getFullYear() &&
          existingStartDate.getMonth() === selectedStartDate.getMonth() &&
          existingStartDate.getDay() === selectedStartDate.getDay() &&
          existingStartDate.getHours() === selectedStartDate.getHours() &&
          existingStartDate.getMinutes() === selectedStartDate.getMinutes()
        );
      }
    });
    return (
      (previousPlannedMessage &&
        currentTimeSelection.selectedStartTime ===
        previousPlannedMessage.startDateTime) ||
      !checkIfOverlap.includes(true)
    );
  };

  static isFormValid = (
    isDescriptionValid,
    isDateAndTimeValid,
    isResponseValid,
    isEndTimeValid
  ) => {
    return (
      isDescriptionValid &&
      isDateAndTimeValid &&
      isResponseValid &&
      isEndTimeValid
    );
  };

  static isPlannedMessagesFormValid = (
    isDescriptionValid,
    isDateAndTimeValid,
    isResponseValid,
    isEndTimeValid,
    isStartTimeValid
  ) => {
    return (
      isDescriptionValid &&
      isDateAndTimeValid &&
      isResponseValid &&
      isEndTimeValid &&
      isStartTimeValid
    );
  };

  static isStartTimeValid = ({ currentTime, selectedStartTime }) => {
    return selectedStartTime >= currentTime;
  };

  static isDateValid = ({ selectedStartTime, selectedEndTime }) => {
    const startDate = this.getAsDateTime(selectedStartTime);
    const endDate = this.getAsDateTime(selectedEndTime);
    return startDate.isSame(endDate) || startDate.isBefore(endDate);
  };

  /**
   * Should the date auto switch to the next day
   * @param {String} startDateTime is the start date and time
   * @param {String} endDateTime is the end date and time
   * @returns {Boolean} true if the date should auto switch, otherwise returns false
   */
  static shouldAutoSwitchDate(startDateTime, endDateTime) {
    let autoSwitch = false;
    const startDate = this.getAsDateTime(startDateTime);
    const endDate = this.getAsDateTime(endDateTime);

    // Month day and year are the same AND the end time is less than the start time
    if (
      startDate.isSame(endDate, DAY) &&
      startDate.diff(endDate, HOUR_FORMAT) > 0
    ) {
      autoSwitch = true;
    }

    return autoSwitch;
  }

  static getDurationForTimesOnly(startDateTime, endDateTime) {

    let tempDate;
    let startDate = this.getAsDateTime(startDateTime);
    tempDate = moment();
    tempDate.set({ "hour": startDate.hours(), "minute": startDate.minutes(), "seconds": startDate.seconds() })
    startDate = tempDate;

    let tempDate1 = moment();
    let endDate = this.getAsDateTime(endDateTime);
    tempDate1.set({ "hour": endDate.hours(), "minute": endDate.minutes(), "seconds": endDate.minutes() })
    endDate = tempDate1;

    return this.getDuration(startDateTime, endDateTime, false);
  }

  /**
   * Get the duration between two dates
   * @param {Date} startDateTime is the start date and time 
   * @param {Date} endDateTime is the end date and time
   * @returns {String} the formatted duration as 1d 2h 5m (zero values suppressed)
   */
  static getDuration(startDateTime, endDateTime, includeDays) {
    let durationInfo = {
      days: 0,
      hours: 0,
      mins: 0,
      totalInMins: 0,
      formattedDuration: '',
      totalInSecs: 0
    };

    try {
      const start = this.getAsDateTime(startDateTime);
      const end = this.getAsDateTime(endDateTime);
      let secs = Math.ceil(moment.duration(end.diff(start)).asSeconds());

      // A negative number means a date mismatch and thus do not format
      if (secs > 0) {
        durationInfo.totalInSecs = secs;

        const d = moment.duration(end.diff(start), 'milliseconds');
        const day = Math.floor(d.asDays());

        const mom = moment.utc(secs * 1000);
        const hour = mom.format('H');
        const min = mom.format('m');

        let formattedDuration = '';
        if (day != '0' && includeDays) {
          formattedDuration += ` ${day}d`;
          durationInfo.days = Number(day);
        }

        if (hour !== '0') {
          formattedDuration += ` ${hour}h`;
          durationInfo.hours = Number(hour);
        }

        if (min !== '0') {
          formattedDuration += ` ${min}m`;
          durationInfo.mins = Number(min);
        }

        durationInfo.formattedDuration = formattedDuration.trim();
        durationInfo.totalInMins = (durationInfo.hours * 60) + durationInfo.mins;
      }

    } catch (err) {
      console.error(`Could not get the duration for ${startDateTime} to ${endDateTime} due to ${err}`);
    }

    return durationInfo;
  }

  /**
   * Gets the date and time as a date object
   * @param {String} dateTime is the date and time
   * @returns {Moment} an actual date object
   */
  static getAsDateTime(dateTime) {
    return moment.tz(dateTime, Constants.TimeZone.PACIFIC_TIME).seconds(0);
  }

  /**
   * Adjust the date based on a specific value and interval
   * @param {Date} date is the date to adjust
   * @param {Number} value is the number value (1,2,3, etc)
   * @param {String} interval is the interval (days, hours, etc)
   * @returns {Moment} the newly adjusted date
   */
  static adjustDate(date, value, interval) {
    return moment(date).add(value, interval);
  }

  /**
   * Check if the start date is before the end date
   * @param {JSON} times contains the start time and end time
   * @returns {Boolean} true if the start date is before the end date, otherwise returns false
   */
  static isStartTimeBeforeEndTime = hours => {
    const startTime = moment(hours.startTime, HOUR_FORMAT);
    const endTime = moment(hours.endTime, HOUR_FORMAT);
    return startTime.isBefore(endTime);
  };

  /**
   * Check if the start and end times are valid
   * @param {Date} startDateTime is the start date and time
   * @param {Date} endDateTime is the end date and time
   * @returns {JSON} true if the overlap is valid otherwise returns false
   */
  static isStartAndEndTimeValid = (startDateTime, endDateTime) => {
    const duration = this.getDuration(startDateTime, endDateTime, false);
    return duration.totalInMins > 0;
  }

  static isScheduleCheckedOff = schedule => {
    return schedule.includes(true);
  };

  static isRecurringMeetingFormValid = (
    isDescriptionValid,
    isDateValid,
    isResponseValid,
    isStartDateValid,
    isStartDateAndEndTimeTheSameValid,
    scheduleCheck
  ) => {
    return (
      isDescriptionValid &&
      isDateValid &&
      isResponseValid &&
      isStartDateValid &&
      isStartDateAndEndTimeTheSameValid &&
      scheduleCheck
    );
  };

  static isLocationClosureFormValid = (
    isDescriptionValid,
    isDateAndTimeValid,
    isStartTimeValid,
    isEndTimeValid
  ) => {
    return (
      isDescriptionValid &&
      isDateAndTimeValid &&
      isStartTimeValid &&
      isEndTimeValid
    );
  };

  static isLocationClosureStartTimeValid = ({
    selectedStartTime,
    currentTime,
  }) => {
    return selectedStartTime > currentTime;
  };

  static sortMessagesByDate = messages => {
    return messages.sort((a, b) => {
      return a.startDateTime - b.startDateTime;
    });
  };

  static sortMessagesByDateRecurring = messages => {
    return messages.sort((a, b) => {
      return a.startDate - b.startDate;
    });
  };

  static isUtteranceValid = utterance => {
    return (
      utterance.length > 0 &&
      utterance.length <= 200 &&
      /^[a-zA-Z' {}_.-]+$/.test(utterance)
    );
  };

  static areUtterancesValid = utterances => {
    const checkIfValid = utterances.map(x => {
      if (x.status === UtteranceStatus.PENDING) {
        return (
          x.utterance.length > 0 &&
          x.utterance.length <= 200 &&
          /^[a-zA-Z' {}_.-]+$/.test(x.utterance)
        );
      }
      return true;
    });

    return !checkIfValid.includes(false);
  };

  static areUtterancesOverlapingIntents = (utterances, intents) => {
    const checkIfOverlap = [];

    utterances.forEach(x => {
      intents.forEach(y => {
        if (
          x.status === UtteranceStatus.PENDING &&
          x.utterance.toLowerCase().replace(/\s/g, '') ===
          y.toLowerCase().replace(/\s/g, '')
        ) {
          checkIfOverlap.push(true);
        }
      });
    });

    return checkIfOverlap.includes(true);
  };

  static isUtteranceOverlapingIntents = (utterance, intents) => {
    const checkIfOverlap = intents.map(x => {
      if (
        utterance.toLowerCase().replace(/\s/g, '') ===
        x.toLowerCase().replace(/\s/g, '')
      ) {
        return true;
      }
      return false;
    });

    return checkIfOverlap.includes(true);
  };

  static getMatchingStringIndex = (utterance, allUtterances) => {
    for (let i = 0; i < allUtterances.length; i++) {
      if (allUtterances[i] === utterance) {
        return i;
      }
    }
  };
}
