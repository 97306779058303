import Logger from '../modules/Logger.js';
import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import Enums from '../modules/Enums';
import Utils from '../modules/Utils';
import TimeZone from 'moment-timezone';
import moment from 'moment';
import Constants from '../modules/Constants';
import Description from '../modules/Description';

import EndMessage from './EndMessage';
import EditAddRecurringMessages from './EditAddRecurringMessages.js';
import DescriptionEditModal from './DescriptionEditModal.js';

// UI
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Container, Table, Col, Collapse, OverlayTrigger,
  Popover
} from 'react-bootstrap';

// API
import RecurringMessagesModule from '../modules/RecurringMessages';

TimeZone.tz.setDefault(Constants.TimeZone.PACIFIC_TIME);

export default class RecurringMessages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recurringMessages: null,
      showModal: false,
      showEndMessage: false,
      currentMessageSelectedIndex: null,
      isLoading: false,
      currentSectionExpanded: true,
      expiredSectionExpanded: false,
      descriptionModal: false,
      description: null,
      idJwtToken: null,
    };
  }

  componentDidMount() {
    if (this.state.recurringMessages === null) {
      this.loadRecurringMessages();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== undefined
      && prevProps.currentLineOfBusiness !== this.props.currentLineOfBusiness
    ) {
      this.loadRecurringMessages();
    }
  }

  async loadRecurringMessages() {
    if (this.props.currentLineOfBusiness === undefined) {
      return;
    }
    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();

    if (idJwtToken) {
      this.setState({ isLoading: true });

      try {
        const recurringMessages =
          await RecurringMessagesModule.get(
            Enums.LinesOfBusinessForAPI[this.props.currentLineOfBusiness],
            idJwtToken,
          );

        const description =
          await Description.getDescriptionMessage(
            'recurringMessage',
            idJwtToken,
          );

        this.setState({
          recurringMessages,
          isLoading: false,
          description,
          idJwtToken,
        });
      } catch (ex) {
        Logger.error('Error loading recurring messages', ex);
        this.setState({
          isLoading: false,
        });
      }
    } else {
      Logger.verbose(JSON.stringify(`JWT TOKEN is null: ${idJwtToken}`));
    }
  }

  openEditModal = (index) => {
    if (this.state.isLoading) {
      return;
    }
    this.setState({
      showModal: true,
      currentMessageSelectedIndex: index,
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      currentMessageSelectedIndex: null,
    });
  };

  updateLocalMessage = (newMessage) => {
    let copyState = this.state.recurringMessages;
    this.state.currentMessageSelectedIndex === null
      ? copyState = [...copyState, newMessage]
      : copyState[this.state.currentMessageSelectedIndex] = newMessage;
    this.setState({
      recurringMessages: [...copyState],
    });
  }

  deleteLocalPlannedMessage = () => {
    const copyState = this.state.recurringMessages;
    const currentIndex = this.state.currentMessageSelectedIndex;
    const newState = copyState.filter((message, index) =>
      index !== currentIndex
    );

    this.setState({
      recurringMessages: [...newState],
    });
  }

  openDeleteModal = (index) => {
    if (this.state.isLoading) {
      return;
    }
    this.setState({
      showEndMessage: true,
      currentMessageSelectedIndex: index,
    });
  };

  closeEndMessageModal = () => {
    this.setState({
      showEndMessage: false,
      currentMessageSelectedIndex: null,
    });
  }

  toggleCurrentSection = () => {
    this.setState({
      currentSectionExpanded: !this.state.currentSectionExpanded,
    });
  }

  toggleExpiredSection = () => {
    this.setState({
      expiredSectionExpanded: !this.state.expiredSectionExpanded,
    });
  }

  openDescriptionEditModal = () => {
    this.setState({
      descriptionModal: true,
    });
  }

  closeDescriptionModal = () => {
    this.setState({
      descriptionModal: false,
    });
  }

  updateLocalDescription = (newDescription) => {
    this.setState({
      description: newDescription,
    });

    return true;
  }

  scheduleToString = (messageSchedule) => {
    const schedule = [];
    messageSchedule.forEach((dayBool, index) => {
      if (dayBool) {
        switch (index) {
          case 0:
            schedule.push('Su');
            break;
          case 1:
            schedule.push('M');
            break;
          case 2:
            schedule.push('Tu');
            break;
          case 3:
            schedule.push('W');
            break;
          case 4:
            schedule.push('Th');
            break;
          case 5:
            schedule.push('F');
            break;
          case 6:
            schedule.push('Sa');
            break;
        }
      }
    });

    return schedule.join(', ');
  };

  buildTableRows = (recurringMessages) => {
    const upcomingOrCurrent = [];
    const expired = [];

    const sortedMessageObjects = Utils.sortMessagesByDateRecurring(
      recurringMessages
    );

    sortedMessageObjects.map((messageObject, index) => {
      const startDateTime = moment
        .tz(messageObject.startDate, Constants.TimeZone.PACIFIC_TIME)
        .format('L');
      const endDateTime = moment
        .tz(messageObject.endDate, Constants.TimeZone.PACIFIC_TIME)
        .format('L');

      const schedule = this.scheduleToString(messageObject.schedule);

      const startTime = moment(messageObject.startTime, 'HH:mm');
      const endTime = moment(messageObject.startTime, 'HH:mm')
        .add(parseInt(messageObject.duration, 10), 'minute');

      const current =
        messageObject.endDate >= moment().tz('America/Los_Angeles');
      const formattedDuration = Utils.getDurationForTimesOnly(messageObject.startDate, messageObject.endDate).formattedDuration;

      const iconColumn = current
        ? <td className="iconColumn">
          <FontAwesomeIcon
            className="editIcon action-icon mr-2"
            icon="pen"
            onClick={() => this.openEditModal(index)}
          />
          <FontAwesomeIcon
            className="trash-alt action-icon"
            icon="trash-alt"
            onClick={() => this.openDeleteModal(index)}
          />
        </td>
        : <td className="iconColumn">
          <div className="iconPlaceholder mr-2 ml-2" />
          <FontAwesomeIcon
            className="trash-alt action-icon"
            icon="trash-alt"
            onClick={() => this.openDeleteModal(index)}
          />
        </td>;

      const value = <tr key={index} className="recurringMessageRow">
        {iconColumn}

        <td className="descriptionColumn">{messageObject.description}</td>
        <td className="dateColumn">{startDateTime}</td>
        <td className="dateColumn">{endDateTime}</td>
        <td className="scheduleColumn">{schedule}</td>
        <td className="timeColumn">{startTime.format('h:mm a [PST]')}</td>
        <td className="timeColumn">{endTime.format('h:mm a [PST]')}</td>
        <td className="timeColumn">{formattedDuration}</td>
      </tr>;

      current
        ? upcomingOrCurrent.push(value)
        : expired.push(value);
    });

    return {
      upcomingOrCurrent,
      expired,
    };
  }

  buildTableHeads = () => {
    return <thead>
      <tr>
        <th></th>
        <th>
          Description
        </th>
        <th>
          Start Date
        </th>
        <th>
          End Date
        </th>
        <th>
          Schedule
        </th>
        <th>
          Start Time
        </th>
        <th>
          End Time
        </th>
        <th>
          Duration
        </th>
      </tr>
    </thead>;
  }

  editAddRecurringMessages = (index) => {
    return <EditAddRecurringMessages
      modalStatus={this.state.showModal}
      closeModal={this.closeModal}
      recurringMessages={this.state.recurringMessages[index]}
      currentLineOfBusiness={this.props.currentLineOfBusiness}
      updateLocalMessage={this.updateLocalMessage}
      userName={this.props.userName}
    />;
  }

  endRecurringMessage = (index) => {
    const idJwtToken = this.state.idJwtToken;

    if (idJwtToken) {
      const textValues = {
        title: 'Delete Recurring Message',
        message: 'Are you sure you want to delete this recurring message?',
        buttonText: 'Delete Recurring Message',
      };

      const updateFunction = () => RecurringMessagesModule.deleteRecurringMessage(
        {
          lobId: Number(
            Enums.LinesOfBusinessForAPI[this.props.currentLineOfBusiness]
          ),
          recurringMessageId: this.state.recurringMessages[index]
            .recurringMessageId,
          idJwtToken,
        }
      );

      return <EndMessage
        endModalStatus={this.state.showEndMessage}
        closeModal={this.closeEndMessageModal}
        message={this.state.recurringMessages[index]}
        updateFunction={updateFunction}
        textValues={textValues}
        updateLocalMessage={this.deleteLocalPlannedMessage}
      />;
    } else {
      Logger.verbose(JSON.stringify(`JWT TOKEN is null: ${idJwtToken}`));
    }
  }

  render() {
    const tableValues = this.state.recurringMessages
      ? this.buildTableRows(this.state.recurringMessages)
      : null;

    const tableHeaders = this.buildTableHeads();
    const editModal = this.state.showModal
      ? this.editAddRecurringMessages(this.state.currentMessageSelectedIndex)
      : null;
    const endModal = this.state.showEndMessage
      ? this.endRecurringMessage(this.state.currentMessageSelectedIndex)
      : null;
    const currentStateClass = this.state.currentSectionExpanded
      ? ''
      : 'collapsed';

    const expiredStateClass = this.state.expiredSectionExpanded
      ? ''
      : 'collapsed';
    const descriptionModal = this.state.descriptionModal
      ? <DescriptionEditModal
        modalStatus={this.state.descriptionModal}
        closeModal={this.closeDescriptionModal}
        currentLineOfBusiness={this.props.currentLineOfBusiness}
        updateLocalMessage={this.updateLocalDescription}
        description={this.state.description}
        title={'Recurring Message Description'}
        messageType={'recurringMessage'}
      />
      : null;

    return (
      <div className="recurringMessageTable">
        <Container className="containerBody mb-2">
          <div className="businessHoursTitle">
            Recurring Messages (PST)
            <OverlayTrigger trigger="click" placement="right" overlay={
              <Popover
                id="popover-basic" title="Recurring Message Description">
                {this.state.description}
              </Popover>
            }>
              <span>
                <FontAwesomeIcon
                  className="editIcon action-icon mr-2"
                  icon="comment"
                  variant="success"
                />
              </span>
            </OverlayTrigger>
            {this.props.permissions.isGlobalAdmin ? <FontAwesomeIcon
              className="editIcon action-icon mr-2"
              icon="pen"
              onClick={() => this.openDescriptionEditModal()}
            /> : null}
          </div>

          <div className={currentStateClass}>
            <div className="formLabel section-header"
              onClick={this.toggleCurrentSection} aria-hidden>
              <FontAwesomeIcon
                className="caret-down"
                icon="caret-down"
              />
              Current and Future
            </div>
            <Col>
              <Collapse in={this.state.currentSectionExpanded}>
                <div>
                  <div className="formLabel add-planned-message-link"
                    onClick={() => this.openEditModal(null)}
                    role="link"
                    tabIndex={0}>
                    <FontAwesomeIcon
                      className="plus add-planned-message-icon"
                      icon="plus"
                    />
                    Add Recurring Message
                  </div>
                  <Table striped hover responsive size="sm">
                    {tableHeaders}
                    <tbody>
                      {tableValues
                        ? tableValues.upcomingOrCurrent
                        : null
                      }
                    </tbody>
                  </Table>
                </div>
              </Collapse>
            </Col>
          </div>
          <div className={expiredStateClass}>
            <div className="formLabel section-header"
              onClick={this.toggleExpiredSection}>
              <FontAwesomeIcon
                className="caret-down"
                icon="caret-down"
              />
              Expired
            </div>
            <Col>
              <Collapse in={this.state.expiredSectionExpanded}>
                <div>
                  <Table striped hover responsive size="sm">
                    {tableHeaders}
                    <tbody>
                      {tableValues
                        ? tableValues.expired
                        : null
                      }
                    </tbody>
                  </Table>
                </div>
              </Collapse>
            </Col>
          </div>
        </Container>
        {editModal}
        {endModal}
        {descriptionModal}
      </div>
    );
  }
}
