import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { HashRouter } from 'react-router-dom';
import './App.css';

import AdminSiteContainer from './containers/AdminSiteContainer';
import AgentContainer from './containers/AgentContainer';
import AdminContainer from './containers/AdminContainer';
import RealTimeContainer from './containers/RealTimeContainer';


import Logger from './modules/Logger.js';
import AmplifySettings from './modules/AmplifyConfigure.js';

import LinesOfBusiness from './modules/LinesOfBusiness';
import BusinessHours from './modules/BusinessHours';
import Permissions from './modules/Permissions';
import ResourceManagerStatus from './modules/Status';
import { LinesOfBusinessForAPI, LinesofBusinessForUI } from './modules/Enums';
import Utils from './modules/Utils';

import NavigationBar from './components/NavigationBar';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faCaretDown, faPlus, faTrashAlt, faComment, faExternalLinkAlt, faSpinner, faPlusCircle }
  from '@fortawesome/free-solid-svg-icons';
import {
  faCheckCircle as farCheckCirle, faTimesCircle as farTimesCircle,
  faComment as farComment,
}
  from '@fortawesome/free-regular-svg-icons';

import LoadingIndicator from '@starbucks/pattern-library/lib/components/loading-indicator';

import Amplify from 'aws-amplify';
import { Auth } from 'aws-amplify';
import { withOAuth } from 'aws-amplify-react';

library.add(
  faPen,
  faCaretDown,
  faPlus,
  faTrashAlt,
  faComment,
  farComment,
  farCheckCirle,
  farTimesCircle,
  faExternalLinkAlt,
  faSpinner,
  faPlusCircle
);

AmplifySettings.config();
const oauth = AmplifySettings.oauth;
Auth.configure({ oauth });

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      linesOfBusiness: [],
      currentLineOfBusiness: '',
      businessHours: [],
      editableHours: [],
      isLoggedIn: false,
      userName: null,
      auth: {},
      signInloading: false,
      status: null,
      permissions: null,
      isLoading: false,
    };
  }

  componentDidMount() {
    const envName = process.env.REACT_APP_ENV_NAME;
    if (envName) {
      document.title = `Connect Admin ${envName}`
    } else 
      document.title = 'Connect Admin'
    setTimeout(this.checkLoggedIn, 500);
  }

  checkLoggedIn = async () => {
    try {
      const isUserSignedIn = await Auth.currentAuthenticatedUser();
      try {
        const session = await Auth.currentSession();
        const idJwtToken = session.getIdToken().getJwtToken();

        if (idJwtToken) {
          this.setState({
            signInloading: true,
          });
          
          const userName = isUserSignedIn.signInUserSession.idToken.payload.email;
          const permissions = await Permissions.getPermissions(idJwtToken);

          if (!permissions.isGlobalAdmin
            && !permissions.isStarbucksSupervisor
            && !permissions.isLocationSupervisor) {
            // TODO: User does not have access to application
            this.setState({
              isLoggedIn: true,
              userName: userName,
              auth: { ...Auth },
              signInloading: false,
              permissions: permissions,
            });
            return;
          }

          const linesOfBusiness = Object.keys(
            permissions.contactCenterPermissions,
          );

          const formattedLOB = Utils.formatLOBForUI(linesOfBusiness,
            LinesofBusinessForUI);

          let businessHours = null;
          let editableHours = null;
          let resourceManagerStatus = null;
          if (permissions.resourceManagerPermissions.canSeeContactCenterPage) {
            businessHours = await BusinessHours.getBusinessHours(
              linesOfBusiness[0],
              idJwtToken,
            );

            editableHours = businessHours !== null
              ? Utils.transformToEditableHours(businessHours)
              : null;

            resourceManagerStatus = await ResourceManagerStatus.getStatus(
              linesOfBusiness[0],
              idJwtToken
            );

            setInterval(this.updateResourceManagerStatus, 60000);
          }

          this.setState({
            linesOfBusiness: formattedLOB,
            currentLineOfBusiness: formattedLOB[0],
            businessHours: businessHours,
            editableHours: editableHours,
            isLoggedIn: true,
            userName: userName,
            auth: { ...Auth },
            signInloading: false,
            status: resourceManagerStatus,
            permissions: permissions,
          });
        } else {
          Logger.warn('JWT Token is null', idJwtToken);
          this.setState({
            isLoggedIn: isUserSignedIn,
            linesOfBusiness: [],
            signInloading: false,
          });
        }
      } catch (err) {
        Logger.error('error: ' + err);
        this.setState({
          isLoggedIn: isUserSignedIn,
          linesOfBusiness: [],
          signInloading: false,
        });
      }
    } catch (err) {
      this.setState({
        auth: { ...Auth },
      });
    }
  }

  onRouteChanged() {
    this.setState({ routeHash: window.location.hash });
  }

  resetBusinessHours = async () => {
    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();

    if (idJwtToken) {
      const businessHours = await BusinessHours.getBusinessHours(
        LinesOfBusinessForAPI[this.state.currentLineOfBusiness],
        idJwtToken,
      );
      const editableHours = Utils.transformToEditableHours(businessHours);

      this.setState({
        businessHours: businessHours,
        editableHours: editableHours,
      });
    } else {
      Logger.verbose(JSON.stringify(`JWT TOKEN is null: ${idJwtToken}`));
    }
  };

  setBusinessHours = async (newHours) => {
    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();

    if (idJwtToken) {
      try {
        await BusinessHours.updateBusinessHours(
          LinesOfBusinessForAPI[this.state.currentLineOfBusiness],
          newHours,
          idJwtToken,
        );

        this.setState({
          businessHours: newHours,
        });

        return true;
      } catch (err) {
        return false;
      }
    } else {
      Logger.verbose(JSON.stringify(`JWT TOKEN is null: ${idJwtToken}`));
    }
  };

  setEditableHours = (newHours) => {
    this.setState({
      editableHours: newHours,
    });
  };

  changeLineOfBusiness = async (lineOfBusiness) => {
    this.setState({
      isLoading: true,
    });
    const selectedLineofBusiness = lineOfBusiness.target.value;
    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();

    if (idJwtToken) {
      if (selectedLineofBusiness !== this.state.currentLineOfBusiness) {
        const businessHours = await BusinessHours.getBusinessHours(
          LinesOfBusinessForAPI[selectedLineofBusiness],
          idJwtToken,
        );
        const editableHours = Utils.transformToEditableHours(businessHours);
        const resourceManagerStatus = await ResourceManagerStatus.getStatus(
          LinesOfBusinessForAPI[selectedLineofBusiness],
          idJwtToken,
        );

        this.setState({
          currentLineOfBusiness: selectedLineofBusiness,
          businessHours: businessHours,
          editableHours: editableHours,
          status: resourceManagerStatus,
          isLoading: false,
        });
      }
    } else {
      Logger.verbose(JSON.stringify(`JWT TOKEN is null: ${idJwtToken}`));
    }
  };

  updateResourceManagerStatus = async () => {
    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();

    if (idJwtToken) {
      const resourceManagerStatus = await ResourceManagerStatus.getStatus(
        LinesOfBusinessForAPI[this.state.currentLineOfBusiness],
        idJwtToken
      );

      this.setState({
        status: resourceManagerStatus,
      });
    } else {
      Logger.verbose(JSON.stringify(`JWT TOKEN is null: ${idJwtToken}`));
    }
  };

  render() {
    let content;
    const permissions = this.state.permissions;

    if (this.state.isLoggedIn && (permissions !== undefined
      && (permissions.isGlobalAdmin
        || permissions.isStarbucksSupervisor
        || permissions.isLocationSupervisor))) {

      const rmPermissions = permissions.resourceManagerPermissions;

      // Contact center admin page
      const contactCenterAdminPage = rmPermissions.canSeeContactCenterPage
        ? <Route path={'/call-center'} render={() => (
          <AdminSiteContainer
            linesOfBusiness={this.state.linesOfBusiness}
            currentLineOfBusiness={this.state.currentLineOfBusiness}
            changeLineOfBusiness={this.changeLineOfBusiness}
            userName={this.state.userName}
            status={this.state.status}
            permissions={this.state.permissions}
          />
        )} />
        : null;

      // Real time admin page
      const realTimePage = rmPermissions.canSeeRealTimePage
        ? <Route path={'/real-time'} render={() => (
          <RealTimeContainer
            linesOfBusiness={this.state.linesOfBusiness}
            currentLineOfBusiness={this.state.currentLineOfBusiness}
            changeLineOfBusiness={this.changeLineOfBusiness}
            userName={this.state.userName}
            status={this.state.status}
            permissions={this.state.permissions}
          />
        )} />
        : null;

      // Agent admin page
      const agentAdminPage = rmPermissions.canSeeAgentPage
        ? <Route path={'/agents'} render={() => (
          <AgentContainer
            linesOfBusiness={this.state.linesOfBusiness}
            currentLineOfBusiness={this.state.currentLineOfBusiness}
            changeLineOfBusiness={this.changeLineOfBusiness}
            status={this.state.status}
            userName={this.state.userName}
          />
        )} />
        : null;

      // Lex admin page

      // Global admin page
      const globalAdminPage = rmPermissions.canSeeGlobalAdminPage
        ? <Route path={'/admin'} render={() => (
          <AdminContainer
            userName={this.state.userName}
            linesOfBusiness={this.state.linesOfBusiness}
            currentLineOfBusiness={this.state.currentLineOfBusiness}
            changeLineOfBusiness={this.changeLineOfBusiness}
            hoursOfBusiness={this.state.businessHours}
            editableHours={this.state.editableHours}
            setBusinessHours={this.setBusinessHours}
            setEditableHours={this.setEditableHours}
            resetBusinessHours={this.resetBusinessHours}
            status={this.state.status}
            permissions={this.state.permissions}
          />
        )} />
        : null;

      const redirectLocation = rmPermissions.canSeeContactCenterPage
        ? '/call-center'
        : '/real-time';

      if (this.state.isLoading) {
        content =
          <div className="container">
            <LoadingIndicator className="indicator-container"
              active
            />
          </div>;
      } else {
        content =
          <HashRouter>
            <Switch>
              {contactCenterAdminPage}
              {realTimePage}
              {agentAdminPage}
              {globalAdminPage}
              <Redirect to={redirectLocation} />
            </Switch>
          </HashRouter>;
      }

    } else {
      content = <div className="no-permissions mt-4">
        Please sign in. If you do not have access, please contact
        your manager or visit the Starbucks Service Portal.
      </div>;
    }

    return <div>
      <NavigationBar
        auth={this.state.auth}
        isloggedIn={this.state.isLoggedIn}
        userName={this.state.userName}
        signInloading={this.state.signInloading}
        permissions={this.state.permissions}
        location={this.state.routeHash}
      />
      {content}
    </div>;
  }
}

export default withOAuth(App);
