import Settings from "./Settings.js";
import Logger from "./Logger.js";
import apigClientFactory from "aws-api-gateway-client";

export default class TemporaryMessages {
  static getTemporaryMessages = async (LOB, idJwtToken) => {
    const config = {
      invokeUrl: Settings.starbucksAPIURL,
    };
    const apigClient = apigClientFactory.newClient(config);
    const pathParams = {};
    const pathTemplate = `/lob/${LOB}/temporarymessages`;
    const method = "GET";
    const additionalParams = {
      headers: {
        Authorization: idJwtToken,
      },
    };
    const body = {};

    const lob = await apigClient.invokeApi(
      pathParams,
      pathTemplate,
      method,
      additionalParams,
      body
    );
    Logger.verbose(JSON.stringify(lob));
    return lob.data.temporaryMessages;
  };

  static updateTempMessage = async ({ LOB, index, message, idJwtToken }) => {
    const config = {
      invokeUrl: Settings.starbucksAPIURL,
    };
    const apigClient = apigClientFactory.newClient(config);
    const pathParams = {};
    const pathTemplate = `/lob/${LOB}/temporarymessages/${index}`;
    const method = "POST";
    const additionalParams = {
      headers: {
        Authorization: idJwtToken,
      },
    };
    const body = {
      ...message,
    };

    try {
      const updateTempMessage = await apigClient.invokeApi(
        pathParams,
        pathTemplate,
        method,
        additionalParams,
        body
      );

      Logger.verbose(
        JSON.stringify(
          `Line of Business updated: ${updateTempMessage.data.temporaryMessages}`
        )
      );

      return true;
    } catch (err) {
      Logger.error(
        "Exception Updating Temporary Message: " + JSON.stringify(err)
      );
      return false;
    }
  };
}
