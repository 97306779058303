import Settings from './Settings.js';
import Logger from './Logger.js';
import apigClientFactory from 'aws-api-gateway-client';
import Authorization from './Authorization';
import { getBatches } from '../util/bulkImportUtil.js';
export default class Agents {

  static getAgents = async (LOB, idJwtToken) => {
    const config = {
      invokeUrl: Settings.starbucksAPIURL,
    };
    const apigClient = apigClientFactory.newClient(config);
    const pathParams = {};
    const pathTemplate = `/lob/${LOB}/agents`;
    const method = 'GET';
    const additionalParams = {
      headers: {
        Authorization: idJwtToken,
      },
    };
    const body = {};
    const lob = await apigClient.invokeApi(
      pathParams,
      pathTemplate,
      method,
      additionalParams,
      body
    );
    Logger.verbose(JSON.stringify(lob));
    return lob.data;
  }

  static updateAgentAutoAcceptCall = async (
    agentId,
    AutoAcceptCall,
    idJwtToken,
  ) => {
    const config = {
      invokeUrl: Settings.starbucksAPIURL,
    };
    const apigClient = apigClientFactory.newClient(config);
    const pathParams = {
    };
    const pathTemplate = `/lob/agent/${agentId}`;
    const method = 'POST';
    const additionalParams = {
      headers: {
        Authorization: idJwtToken,
      },
    };
    const body = {
      AutoAcceptCall,
    };
    try {
      const updateAgent = await apigClient.invokeApi(
        pathParams,
        pathTemplate,
        method,
        additionalParams,
        body
      );
      Logger.verbose(JSON.stringify(
        `Agent updated. Response: ${JSON.stringify(updateAgent.data)}`));
      return true;
    } catch (err) {
      Logger.error('Exception Update agents: ' +
        JSON.stringify(err));
      return false;
    }
  }

  static getUserModal = async (amazonId, idJwtToken) => {
    const config = {
      invokeUrl: Settings.starbucksAPIURL,
    };
    const apigClient = apigClientFactory.newClient(config);
    const pathParams = {};
    const pathTemplate = `/getUserInfo/${amazonId}`;
    const method = 'POST';
    const additionalParams = {
      headers: {
        Authorization: idJwtToken,
      },
    };
    const body = {};
    const lob = await apigClient.invokeApi(
      pathParams,
      pathTemplate,
      method,
      additionalParams,
      body
    );
    return lob.data;
  }

  static updateUserIdentity = async (
    agentId,
    firstName,
    lastName,
    idJwtToken,
  ) => {
    const config = {
      invokeUrl: Settings.starbucksAPIURL,
    };
    const apigClient = apigClientFactory.newClient(config);
    const pathParams = {
    };
    const pathTemplate = `/updateUserIdentityInfo/${agentId}`;
    const method = 'PUT';
    const additionalParams = {
      headers: {
        Authorization: idJwtToken,
      },
    };
    const body = {
      'IdentityInfo': {
        'FirstName': firstName,
        'LastName': lastName,
      },
    };
    try {
      const updateAgent = await apigClient.invokeApi(
        pathParams,
        pathTemplate,
        method,
        additionalParams,
        body
      );
      Logger.verbose(JSON.stringify(
        `User Identity updated. Response: ${JSON.stringify(updateAgent.data)}`));
      return true;
    } catch (err) {
      Logger.error('Exception Update User Id: ' +
        JSON.stringify(err));
      return false;
    }
  }

  static updateUserPhoneConfig = async (
    agentId,
    deskPhone,
    softOrDesk,
    workTimeOut,
    autoAccept,
    idJwtToken,
  ) => {
    const config = {
      invokeUrl: Settings.starbucksAPIURL,
    };
    const apigClient = apigClientFactory.newClient(config);
    const pathParams = {
    };
    const pathTemplate = '/updateUserPhoneConfig';
    const method = 'PUT';
    const additionalParams = {
      headers: {
        Authorization: idJwtToken,
      },
    };
    const body = {
      'PhoneConfig': {
        'PhoneType': softOrDesk,
        'AfterContactWorkTimeLimit': workTimeOut,
        'AutoAccept': autoAccept,
        'DeskPhoneNumber': deskPhone,
      },
      'UserId': agentId,
    };
    try {
      const updatePhone = await apigClient.invokeApi(
        pathParams,
        pathTemplate,
        method,
        additionalParams,
        body
      );
      Logger.verbose(JSON.stringify(
        `Phone Config updated. Response: ${JSON.stringify(updatePhone.data)}`));
      return updatePhone.data;
    } catch (err) {
      Logger.error('Exception Update Phone Config: ' +
        JSON.stringify(err));
      return false;
    }
  }
  static updateUserSoftPhoneConfig = async (
    agentId,
    softOrDesk,
    workTimeOut,
    autoAccept,
    idJwtToken,
  ) => {
    const config = {
      invokeUrl: Settings.starbucksAPIURL,
    };
    const apigClient = apigClientFactory.newClient(config);
    const pathParams = {
    };
    const pathTemplate = '/updateUserPhoneConfig';
    const method = 'PUT';
    const additionalParams = {
      headers: {
        Authorization: idJwtToken,
      },
    };
    const body = {
      'PhoneConfig': {
        'PhoneType': softOrDesk,
        'AfterContactWorkTimeLimit': workTimeOut,
        'AutoAccept': autoAccept,
      },
      'UserId': agentId,
    };
    try {
      const updatePhone = await apigClient.invokeApi(
        pathParams,
        pathTemplate,
        method,
        additionalParams,
        body
      );
      Logger.verbose(JSON.stringify(
        `Phone Config updated. Response: ${JSON.stringify(updatePhone.data)}`));
      return updatePhone.data;
    } catch (err) {
      Logger.error('Exception Update Phone Config: ' +
        JSON.stringify(err));
      return false;
    }
  }
  static updateBulkAutoAccept = async (
    agentId,
    softOrDesk,
    autoAccept,
    idJwtToken,
  ) => {
    const config = {
      invokeUrl: Settings.starbucksAPIURL,
    };
    const apigClient = apigClientFactory.newClient(config);
    const pathParams = {
    };
    const pathTemplate = '/updateUserPhoneConfig';
    const method = 'PUT';
    const additionalParams = {
      headers: {
        Authorization: idJwtToken,
      },
    };
    const body = {
      'PhoneConfig': {
        'PhoneType': softOrDesk,
        'AutoAccept': autoAccept,
      },
      'UserId': agentId,
    };
    try {
      const updatePhone = await apigClient.invokeApi(
        pathParams,
        pathTemplate,
        method,
        additionalParams,
        body
      );
      Logger.verbose(JSON.stringify(
        `Phone Config updated. Response: ${JSON.stringify(updatePhone.data)}`));
      return updatePhone.data;
    } catch (err) {
      Logger.error('Exception Update Phone Config: ' +
        JSON.stringify(err));
      return false;
    }
  }
  static updateBulkTimeOut = async (
    agentId,
    softOrDesk,
    workTimeOut,
    idJwtToken,
  ) => {
    const config = {
      invokeUrl: Settings.starbucksAPIURL,
    };
    const apigClient = apigClientFactory.newClient(config);
    const pathParams = {
    };
    const pathTemplate = '/updateUserPhoneConfig';
    const method = 'PUT';
    const additionalParams = {
      headers: {
        Authorization: idJwtToken,
      },
    };
    const body = {
      'PhoneConfig': {
        'PhoneType': softOrDesk,
        'AfterContactWorkTimeLimit': workTimeOut,
      },
      'UserId': agentId,
    };
    try {
      const updatePhone = await apigClient.invokeApi(
        pathParams,
        pathTemplate,
        method,
        additionalParams,
        body
      );
      Logger.verbose(JSON.stringify(
        `Phone Config updated. Response: ${JSON.stringify(updatePhone.data)}`));
      return updatePhone.data;
    } catch (err) {
      Logger.error('Exception Update Phone Config: ' +
        JSON.stringify(err));
      return false;
    }
  }

  static updateUserRoutingProfile = async (
    agentId,
    routing,
    idJwtToken,
  ) => {
    const config = {
      invokeUrl: Settings.starbucksAPIURL,
    };
    const apigClient = apigClientFactory.newClient(config);
    const pathParams = {
    };
    const pathTemplate = `/updateUserRoutingProfile/${agentId}`;
    const method = 'PUT';
    const additionalParams = {
      headers: {
        Authorization: idJwtToken,
      },
    };
    const body = {
      'RoutingProfileId': routing,
    };
    try {
      const updateRouting = await apigClient.invokeApi(
        pathParams,
        pathTemplate,
        method,
        additionalParams,
        body
      );
      Logger.verbose(JSON.stringify(
        `Routing Id updated. Response: ${JSON.stringify(updateRouting.data)}`));
      return updateRouting.data;
    } catch (err) {
      Logger.error('Exception Update Routing Profile: ' +
        JSON.stringify(err));
      return false;
    }
  }

  static updateUserSecurityProfile = async (
    agentId,
    security,
    idJwtToken,
  ) => {
    const config = {
      invokeUrl: Settings.starbucksAPIURL,
    };
    const apigClient = apigClientFactory.newClient(config);
    const pathParams = {
    };
    const pathTemplate = `/updateUserSecurityProfiles/${agentId}`;
    const method = 'PUT';
    const additionalParams = {
      headers: {
        Authorization: idJwtToken,
      },
    };
    const body = {
      'SecurityProfileIds': security,
    };
    try {
      const updateSecurity = await apigClient.invokeApi(
        pathParams,
        pathTemplate,
        method,
        additionalParams,
        body
      );
      Logger.verbose(JSON.stringify(
        `Security Id updated. Response: ${JSON.stringify(updateSecurity.data)}`));
      return updateSecurity.data;
    } catch (err) {
      Logger.error('Exception Update Security Profile: ' +
        JSON.stringify(err));
      return false;
    }
  }

  static updateUserHierarchyGroup = async (
    agentId,
    hierarchy,
    idJwtToken,
  ) => {
    const config = {
      invokeUrl: Settings.starbucksAPIURL,
    };
    const apigClient = apigClientFactory.newClient(config);
    const pathParams = {
    };
    const pathTemplate = `/updateUserHierarchyById/${agentId}`;
    const method = 'PUT';
    const additionalParams = {
      headers: {
        Authorization: idJwtToken,
      },
    };
    const body = {
      'HierarchyGroupId': hierarchy,
    };
    try {
      const updateHierarchy = await apigClient.invokeApi(
        pathParams,
        pathTemplate,
        method,
        additionalParams,
        body
      );
      Logger.verbose(JSON.stringify(
        `Hierarchy updated. Response: ${JSON.stringify(updateHierarchy.data)}`));
      return true;
    } catch (err) {
      Logger.error('Exception Update Hierarchy Profile: ' +
        JSON.stringify(err));
      return false;
    }
  }
  static getSecurityList = async () => {
    const config = {
      invokeUrl: Settings.starbucksAPIURL,
    };
    const apigClient = apigClientFactory.newClient(config);
    const pathParams = {};
    const pathTemplate = '/getSecurityProfiles';
    const method = 'POST';
    const additionalParams = await Authorization.getAuthHeader();
    const body = {};
    const lob = await apigClient.invokeApi(
      pathParams,
      pathTemplate,
      method,
      additionalParams,
      body
    );

    return lob.data;
  }

  static getRoutingList = async () => {
    const config = {
      invokeUrl: Settings.starbucksAPIURL,
    };
    const apigClient = apigClientFactory.newClient(config);
    const pathParams = {};
    const pathTemplate = '/getRoutingProfiles';
    const method = 'POST';
    const additionalParams = await Authorization.getAuthHeader();
    const body = {};
    const lob = await apigClient.invokeApi(
      pathParams,
      pathTemplate,
      method,
      additionalParams,
      body
    );
    return lob.data;
  }

  static getHierarchyList = async () => {
    const config = {
      invokeUrl: Settings.starbucksAPIURL,
    };
    const apigClient = apigClientFactory.newClient(config);
    const pathParams = {};
    const pathTemplate = '/listUserHierarchyGroups';
    const method = 'GET';
    const additionalParams = await Authorization.getAuthHeader();
    const body = {};
    const lob = await apigClient.invokeApi(
      pathParams,
      pathTemplate,
      method,
      additionalParams,
      body
    );
    return lob.data;
  }

  static createNewUser = async (
    firstName,
    lastName,
    userName,
    softOrDesk,
    workTimeOut,
    autoAccept,
    deskPhone,
    routing,
    security,
    idJwtToken,
  ) => {
    const config = {
      invokeUrl: Settings.starbucksAPIURL,
    };
    const apigClient = apigClientFactory.newClient(config);
    const pathParams = {
    };
    const pathTemplate = `/createUser`;
    const method = 'POST';
    const additionalParams = {
      headers: {
        Authorization: idJwtToken,
      },
    };
    const body = {
      'IdentityInfo': {
        'FirstName': firstName,
        'LastName': lastName,
      },
      'Username': userName,
      'PhoneConfig': {
        'PhoneType': softOrDesk,
        'AfterContactWorkTimeLimit': workTimeOut,
        'AutoAccept': autoAccept,
        'DeskPhoneNumber': deskPhone,
      },
      'RoutingProfileId': routing,
      'SecurityProfileIds': security,
    };
    try {
      const createUser = await apigClient.invokeApi(
        pathParams,
        pathTemplate,
        method,
        additionalParams,
        body
      );
      Logger.verbose(JSON.stringify(
        `Create User. Response: ${JSON.stringify(createUser.data)}`));
      return createUser.data;
    } catch (err) {
      Logger.error('Exception Update Phone Config: ' +
        JSON.stringify(err));
      return false;
    }
  }

  static describeUserList = async (
    bulkDownloadUser,
    idJwtToken,
  ) => {
    const config = {
      invokeUrl: Settings.starbucksAPIURL,
    };
    const apigClient = apigClientFactory.newClient(config);
    const pathParams = {
    };
    const pathTemplate = `/getUsersInfo`;
    const method = 'POST';
    const additionalParams = {
      headers: {
        Authorization: idJwtToken,
      },
    };
    const body = {
      'UserIds': bulkDownloadUser,
    };
    try {
      const describeUsers = await apigClient.invokeApi(
        pathParams,
        pathTemplate,
        method,
        additionalParams,
        body
      );
      Logger.verbose(JSON.stringify(
        `Agent updated. Response: ${JSON.stringify(describeUsers.data)}`));
      return describeUsers.data;
    } catch (err) {
      Logger.error('Exception Update agents: ' +
        JSON.stringify(err));
      return false;
    }
  }

  static agentStatus = async (
    lob,
    idJwtToken,
    permissions
  ) => {
    const config = {
      invokeUrl: Settings.starbucksAPIURL,
    };
    const apigClient = apigClientFactory.newClient(config);
    const pathParams = {
    };
    const pathTemplate = `/getAgentStatus`;
    const method = 'POST';
    const additionalParams = {
      headers: {
        Authorization: idJwtToken,
      },
    };

    const body = {
      'lob': lob,
      securityProfileName: permissions.securityProfileName
    };
    try {
      const agentsStatus = await apigClient.invokeApi(
        pathParams,
        pathTemplate,
        method,
        additionalParams,
        body
      );

      Logger.verbose(JSON.stringify(
        `Agent updated. Response: ${JSON.stringify(agentsStatus.data)}`));
      return agentsStatus.data;
    } catch (err) {
      Logger.error('Exception Update agents: ' +
        JSON.stringify(err));
      return false;
    }
  }

  /** 
   * This function calls a backend endpoint to create new users. 
   *   - Input csv string containing one user per line
   *   - Returns a promise of an array with one element per user { username: "xxx", message: "id or error message"}
   */
  static bulkUserImport = async (
    data,
    idJwtToken,
  ) => {
    const config = {
      invokeUrl: Settings.starbucksAPIURL,
    };
    const pathParams = {
    };
    const pathTemplate = `/createBulkUsers`;
    const method = 'POST';
    const additionalParams = {
      headers: {
        Authorization: idJwtToken,
        'Content-Type': 'text/csv',
      },
    };

    try {
      const apigClient = apigClientFactory.newClient(config);
      let responses = [];

      // Break large requests into smaller batches
      const batches = getBatches(data);

      // Execute all batches synchronously as the backend cannot handle async (TooManyRequestsException)
      for(const [index, batch] of batches.entries()){
        responses[index] = await apigClient.invokeApi(
          pathParams,
          pathTemplate,
          method,
          additionalParams,
          batch);
      }

      // Concatenate all responses
      let apiResponse = [];
      for(const response of responses) {
        if(response && response.data && response.data.responseBody){
          apiResponse = [...apiResponse, ...response.data.responseBody];
        }
      }
   
      return apiResponse;
    } catch (error) {
      const msg = `createBulkUsers api gateway error: "${JSON.stringify(error || '', null, ' ')}".`;
      Logger.error(msg);
      return msg;
    }
  }
}